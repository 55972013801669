import React, { useState } from 'react'

// styles
import { TooltipWrapper, TooltipTip } from './styles'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'

const Tooltip = props => {
  const {
    cursor,
    displayBlock,
    displayFlex,
    forceShow,
    forceShowOnLoad,
    height,
    isWarning,
    minWidth
  } = props
  if (!props.content) {
    return props.children
  }

  const [active, setActive] = useState(forceShowOnLoad || false)

  const forceShowTip = () => {
    if (forceShow) {
      setActive(true)
    }
  }

  const showTip = () => {
    setActive(true)
  }

  const hideTip = () => {
    const hideTipTimeout = forceShow ? 0 : 2000
    setTimeout(() => {
      setActive(false)
    }, hideTipTimeout)
  }

  return (
    <TooltipWrapper
      active={active}
      cursor={cursor}
      displayBlock={displayBlock}
      displayFlex={displayFlex}
      height={height}
      minWidth={minWidth}
      onClick={showTip}
      onTouchEnd={showTip}
      onMouseLeave={hideTip}
      onMouseEnter={forceShowTip}
      position={props.position}
    >
      {props.children}

      <ConditionalDisplay displayWhen={[active]}>
        <TooltipTip
          isWarning={isWarning}
          minWidth={minWidth}
          onClick={hideTip}
          position={props.position}
          tinyTooltip={props.tinyTooltip}
        >
          {props.content}
        </TooltipTip>
      </ConditionalDisplay>
    </TooltipWrapper>
  )
}

export default Tooltip
